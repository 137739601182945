import React from 'react'
import Layout from '../components/layout/layout.component'
import indexStyles from './index.module.scss'

export default function Index() {
  return (
    <Layout hasBackground isMenuWhite>
      <div className={indexStyles.container}>
        <div className={indexStyles.text}>
          <h1>
            “If I don’t paint, I don’t live” <span>Esteban Vicente</span>
          </h1>
        </div>
      </div>
    </Layout>
  )
}
